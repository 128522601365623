<template>
  <main class="hero-foot center" style="padding: 2rem">
    <div id="grid" style="width: 768px">
      <div id="a" class="center-column">
        <p class="has-text-centered has-text-white">
          ​The quickest car in the world, with record-setting<br />
          acceleration, range and performance.
        </p>
      </div>
      <div id="b" class="center-column">
        <p class="title has-text-white">
          <i
            class="fas fa-tachometer-alt has-text-grey-light"
            style="width: 0.75em; height: 0.75em"
          />
          1.9<span class="is-size-5">s</span>
        </p>
        <p class="subtitle has-text-white is-7">0-60 mph</p>
      </div>
      <div id="c" class="center-column" style="border-left: 1px solid gray">
        <p class="title has-text-white">
          <span class="is-size-5">+</span>250<span class="is-size-5">
            mph
          </span>
        </p>
        <p class="subtitle is-7 has-text-white">Top Speed</p>
      </div>
      <div id="d" class="center-column" style="border-left: 1px solid gray">
        <p class="title has-text-white">
          620
          <span class="is-size-5">mi</span>
        </p>
        <p class="subtitle is-7 has-text-white">Mile Range</p>
      </div>
      <div id="e" class="center-column">
        <a
          class="button is-danger is-inverted is-rounded is-outlined has-text-weight-bold"
          style="width: 100%; border: 0.15em solid white"
        >
          Reserve Now
        </a>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "HeroFooter",
};
</script>
