<template>
  <header class="hero-body">
    <!-- Using overlay to center the text -->
    <div class="is-overlay has-text-centered" style="top: 6em">
      <p class="subtitle is-4 has-text-weight-light">Tesla</p>
      <p class="title is-1">Roadster</p>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeroBody",
};
</script>
